import { memo, useState } from 'react';
import { Avatar, Box, CnBalloon, CnI18nSelect, CnMessage } from '@alife/cn-ui';
import { useMount } from 'ahooks';
import { describeCurrentMemberDetail, getUserInfo } from '../api';
import { locale } from '../utils/shared';
import ParkSelect from './park-select';
import $i18n from '@alife/panda-i18n';
import { CN_LOGIN_URL } from '../env';
const Component = ({ langList = ['zh-CN', 'en-US'], needCompanySelect = false, logout, onUserDataLoad, isNewVersion, setLoadedUserInfo, }) => {
    const [userInfo, setUserInfo] = useState();
    const [currentLang, setCurrentLang] = useState($i18n.getLang());
    useMount(() => {
        init();
    });
    const init = async () => {
        if (isNewVersion) {
            const data = await describeCurrentMemberDetail();
            if (data && Object.keys(data).length) {
                // 对accountId 和 userId进行base64解码
                data.account = data.memberName;
                data.accountId = data.memberId;
                setUserInfo(data);
                if (data.status !== 'NORMAL') {
                    CnMessage.show({
                        type: 'error',
                        content: locale?.userStatusError,
                        duration: 3000,
                        closeable: true,
                    });
                    return;
                }
                // 传递数据到外部使用
                if (onUserDataLoad && typeof onUserDataLoad === 'function') {
                    onUserDataLoad(data);
                }
                setLoadedUserInfo(true);
            }
        }
        else {
            const data = await getUserInfo();
            if (data && Object.keys(data).length) {
                // 对accountId 和 userId进行base64解码
                if (data.accountId) {
                    data.accountId = window.atob(data.accountId);
                }
                if (data.userId) {
                    data.userId = window.atob(data.userId);
                }
                setUserInfo(data);
                // 传递数据到外部使用
                if (onUserDataLoad && typeof onUserDataLoad === 'function') {
                    onUserDataLoad(data);
                }
            }
        }
    };
    return (<Box direction='row' spacing={24} align='center'>
      {needCompanySelect && userInfo?.companyId ? (<ParkSelect dataSource={userInfo?.companyList} companyId={String(userInfo.companyId)}/>) : ('')}
      {/* 语言选择 */}
      <div>
        <CnI18nSelect langList={langList} lang={currentLang} onChange={(lang) => {
            setCurrentLang(lang);
            window.location.reload();
        }}/>
      </div>
      {/* 用户信息 */}
      <div>
        <CnBalloon v2 triggerType='click' className='shell-tooltip-header' trigger={<Avatar icon='account'/>} align='br' arrowPointToCenter>
          <div style={{ display: 'flex', color: '#000' }}>
            <div className='shell-tooltip-header-name'>
              <Avatar icon='account'/>
            </div>
            <div className='shell-tooltip-header-title'>
              <h3>{userInfo?.account}</h3>
              <Box direction='row' className='shell-tooltip-header-title-username' align='center'>
                <span>
                  {userInfo?.companyName || userInfo?.accountId || '-'}
                </span>
              </Box>
              <Box className='shell-tooltip-header-title-exit' justify='center' align='center' onClick={() => {
            if (logout && typeof logout === 'function') {
                logout();
            }
            else {
                const url = `${window.location.protocol}//${CN_LOGIN_URL}/logout.htm?isNewLogin=true&redirectURL=${encodeURIComponent(window.location.href)}`;
                window.location.href = url;
            }
        }}>
                {locale?.logoutButton}
              </Box>
            </div>
          </div>
        </CnBalloon>
      </div>
    </Box>);
};
export default memo(Component);
