(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@alife/cn-ui"), require("react-dom"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@alife/cn-ui", "react-dom", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["espCommonShell"] = factory(require("react"), require("@alife/cn-ui"), require("react-dom"), require("lodash"));
	else
		root["espCommonShell"] = factory(root["React"], root["CNUI"], root["ReactDOM"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__18__) {
return 