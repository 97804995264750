import React from 'react';
import { Box, Icon } from '@alife/cn-ui';
const selectImg = 'https://img.alicdn.com/imgextra/i1/O1CN01dXAMby1yK4SMLiY4G_!!6000000006559-55-tps-16-16.svg';
const noSelectImg = 'https://img.alicdn.com/imgextra/i1/O1CN01cNSFHz1gte8TOXat1_!!6000000004200-55-tps-16-16.svg';
export default function MenuItem({ isSelect, tackSelect, text, icon = false, iconSize = 'small', hasArrow, onSelect, onTackSelect, }) {
    const renderIcon = () => {
        if (icon) {
            if (typeof icon === 'boolean') {
                return (<Box style={{ width: 20, marginRight: 10 }} justify='center' align='center'>
            <Box justify='center' align='center' className='menu-item-icon'>
              {text?.substring(0, 1)}
            </Box>
          </Box>);
            }
            if (typeof icon === 'string' &&
                (icon.startsWith('http') || icon.startsWith('//'))) {
                return (<Box style={{ width: 20, marginRight: 10 }} justify='center' align='center'>
            <img src={icon} alt='icon'/>
          </Box>);
            }
            if (typeof icon === 'string') {
                return (<Box style={{ width: 20, marginRight: 10, color: '#818CA1' }} justify='center' align='center'>
            <Icon type={icon} size={iconSize}/>
          </Box>);
            }
        }
        return null;
    };
    return (<Box direction='row' className='menu-item-inner' style={{ backgroundColor: isSelect ? '#f7f8fa' : 'transparent' }} onClick={() => {
            onSelect();
        }}>
      {renderIcon()}
      <Box justify='center' className='text'>
        <span className='menu-item-text' style={{ color: isSelect ? '#3076FC' : '#394252' }}>
          {text}
        </span>
      </Box>
      <Box align='center' direction='row' style={{ marginLeft: 10 }}>
        <div className='no-select' style={{ cursor: 'pointer', height: 16 }} onClick={(e) => {
            e.stopPropagation();
            onTackSelect();
        }}>
          <img src={tackSelect ? selectImg : noSelectImg} alt='tackSelect'/>
        </div>
        <Box style={{ marginLeft: 18 }} justify='center'>
          <div className='iconRight' style={{
            borderColor: isSelect ? '#3076FC' : '#394252',
            opacity: hasArrow ? 1 : 0,
        }}/>
        </Box>
      </Box>
    </Box>);
}
