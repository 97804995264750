import { Box } from '@alife/cn-ui';
import $i18n from '@alife/panda-i18n';
import { isValidElement, memo, useEffect, useState } from 'react';
import { describeMemberMenu, pinMenu, pinMenuNew, queryMenu, unpinMenu, unpinMenuNew, } from '../api';
import { getStartMenuCode, locale } from '../utils/shared';
import { getItemsFromTree, initTree } from '../utils/tree';
import GlobalMenu from './global-menu';
import MenuBar from './menu-bar';
import Logo from './logo';
import ShellRight from './shell-right';
import { BASIC_URL_LIST } from '../utils/config';
const Component = ({ logo, extraRender, logoHref, langList, needCompanySelect = false, logout, onUserDataLoad, hrefBase = '', isNewVersion = false, }) => {
    const [globalMenu, setGlobalMenu] = useState([]);
    const [customMenu, setCustomMenu] = useState([]);
    const [loadedUserInfo, setLoadedUserInfo] = useState(false);
    const getBasicUrl = (href, basic) => {
        let _basic = basic;
        if (!href.includes('#')) {
            if (!basic) {
                _basic = '/workbench#';
                const { hostname } = window.location;
                const isLgg = hostname.includes('lgg.');
                const isKul = hostname.includes('kul.');
                if (isLgg) {
                    _basic = '/workbench/lgg#';
                }
                else if (isKul) {
                    _basic = '/workbench/kul#';
                }
            }
            if (BASIC_URL_LIST.includes(href)) {
                _basic = '/workbench#';
            }
        }
        return `${_basic}${href}`;
    };
    useEffect(() => {
        if (isNewVersion) {
            if (loadedUserInfo) {
                const startMenuCode = hrefBase === 'CINGLEOT' ? 'PC_MENU' : getStartMenuCode();
                describeMemberMenu(startMenuCode).then((res) => {
                    if (res) {
                        const newMenuList = initTree(res, 0, (item) => {
                            const copyItem = item;
                            copyItem.label = $i18n.get({
                                id: `WORKBENCH.MENU.${item.menuCode}`,
                                dm: item.menuTitle,
                            });
                            copyItem.key = item._id;
                            copyItem.tackSelect = !!item.isPersonal;
                            copyItem.href = item.menuUrl;
                            return copyItem;
                        }, 0);
                        const newCustomMenu = [];
                        getItemsFromTree(newMenuList, newCustomMenu);
                        setCustomMenu(newCustomMenu);
                        setGlobalMenu(newMenuList);
                    }
                });
            }
        }
        else {
            queryMenu().then((res) => {
                if (res) {
                    const newMenuList = initTree(res, 0, (item) => {
                        const copyItem = item;
                        copyItem.label = $i18n.get({
                            id: `portal.menu.${item.menuCode}`,
                            dm: item.name,
                        });
                        copyItem.key = item._id;
                        copyItem.tackSelect = !!item.isPersonal;
                        if (copyItem.href) {
                            // /next开头的是L2搭建页面，路由固定为history，不需要拼接前缀
                            if (!copyItem.href.startsWith('/next')) {
                                copyItem.href = getBasicUrl(copyItem.href, hrefBase);
                            }
                        }
                        return copyItem;
                    }, 0);
                    const newCustomMenu = [];
                    getItemsFromTree(newMenuList, newCustomMenu);
                    setCustomMenu(newCustomMenu);
                    setGlobalMenu(newMenuList);
                }
            });
        }
    }, [hrefBase, isNewVersion, loadedUserInfo]);
    const globalMenuTackSelect = async (item, selected) => {
        const data = {
            menuCode: item.menuCode,
        };
        // eslint-disable-next-line no-param-reassign
        item.tackSelect = selected;
        if (selected) {
            let res = null;
            if (isNewVersion) {
                res = await pinMenuNew(data);
            }
            else {
                res = await pinMenu(data);
            }
            if (res) {
                const newCustomMenu = [];
                getItemsFromTree(globalMenu, newCustomMenu);
                setCustomMenu(newCustomMenu);
                setGlobalMenu([...globalMenu]);
            }
        }
        else {
            // 删除
            let res = null;
            if (isNewVersion) {
                res = await unpinMenuNew(data);
            }
            else {
                res = await unpinMenu(data);
            }
            if (res) {
                setCustomMenu(customMenu.filter((it) => it.key !== item.key));
                setGlobalMenu([...globalMenu]);
            }
        }
    };
    const renderExtra = () => {
        if (isValidElement(extraRender)) {
            return extraRender;
        }
        else if (typeof extraRender === 'function' &&
            isValidElement(extraRender())) {
            return extraRender();
        }
        return null;
    };
    return (<Box direction='row'>
      <Box align='center' direction='row'>
        <GlobalMenu globalMenuTitle={locale?.menuGlobalNavigation} dataSource={globalMenu} globalMenuTackSelect={globalMenuTackSelect}/>
        {/* logo */}
        <Logo logo={logo} logoHref={logoHref}/>
      </Box>

      {/* 中间菜单区 */}
      <MenuBar dataSource={customMenu}/>
      {/* 右侧菜单 */}
      <Box direction='row' align='center' spacing={24} padding={[0, 12, 0, 0]}>
        {/* 自定义功能区 */}
        {renderExtra()}
        <ShellRight needCompanySelect={needCompanySelect} langList={langList} logout={logout} onUserDataLoad={onUserDataLoad} isNewVersion={isNewVersion} setLoadedUserInfo={setLoadedUserInfo}/>
      </Box>
    </Box>);
};
export default memo(Component);
