/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';

/**
 * 生成uuid
 * @param {Number} len 长度
 * @param {number} radix 基数
 * @returns String
 */
const uuid = (len, radix) => {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuidArr = [];
  let i;
  const _radix = radix || chars.length;
  if (len) {
    // eslint-disable-next-line no-bitwise
    for (i = 0; i < len; i++) uuidArr[i] = chars[0 | (Math.random() * _radix)];
  } else {
    let r;
    // eslint-disable-next-line no-multi-assign
    uuidArr[8] = uuidArr[13] = uuidArr[18] = uuidArr[23] = '-';
    uuidArr[14] = '4';

    for (i = 0; i < 36; i++) {
      if (!uuidArr[i]) {
        // eslint-disable-next-line no-bitwise
        r = 0 | (Math.random() * 16);
        // eslint-disable-next-line no-bitwise
        uuidArr[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuidArr.join('');
};

/**
 * 树结构数据格式化
 * @param {Array<TreeItem>} tree
 * @param {*} callback
 * @returns
 */
export const formatTree = (tree, callback) => {
  return tree.map((item) => {
    const cloneItem = cloneDeep(item);
    if (callback && typeof callback === 'function') {
      callback(cloneItem);
    }
    if (cloneItem.children?.length) {
      cloneItem.children = formatTree(cloneItem.children, callback);
    }
    return cloneItem;
  });
};

/**
 * 从树结构数组对象查找某一项
 * @param {Array<TreeItem>} tree 树结构数组对象
 * @param {String} key 对象key
 * @param {Any} value 对象值
 * @returns TreeItem 内存指针不变
 */
export const findForTree = (tree, key, value) => {
  let result = null;
  const loop = (data) => {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item[key] === value) {
        result = item;
        break;
      } else if (item.children && item.children.length) {
        loop(item.children);
      }
    }
  };
  loop(tree);
  return result;
};

/**
 * 基础树结构格式化，添加_id, _pid
 * @param {Array<TreeItem>} tree 树结构数组对象
 * @param {any} pid pid
 * @returns tree
 */
export const initTree = (tree, pid, callback, level = 0) => {
  return tree.map((item) => {
    let cloneItem = cloneDeep(item);
    const _id = cloneItem._id || uuid();
    cloneItem._id = _id;
    cloneItem._pid = pid || 0;
    cloneItem._level = level;
    if (callback && typeof callback === 'function') {
      cloneItem = callback(cloneItem);
    }
    if (cloneItem.children?.length) {
      cloneItem.children = initTree(
        cloneItem.children,
        _id,
        callback,
        level + 1,
      );
    }
    return cloneItem;
  });
};

/**
 * 通过ID找到所在树的顶层节点
 * @param {Array<TreeItem>} tree 树结构数组对象
 * @param {Any} id id
 * @returns
 */
export const findTopByIdFormTree = (tree, id) => {
  let result = null;
  const loop = (_id) => {
    const node = findForTree(tree, '_id', _id);
    if (!node._pid) {
      result = node;
    } else {
      loop(node._pid);
    }
  };
  loop(id);
  return result;
};

/**
 * 通过ID集合过滤数据
 * @param {Array<TreeItem>} tree 树结构数组对象
 * @param {Array} ids ids
 * @returns tree
 */
export const filterTreeByIds = (tree, ids) => {
  const cloneTree = cloneDeep(tree);
  return cloneTree.filter((item) => {
    const match = ids.includes(item._id);
    if (!match) {
      item.children = item.children?.length
        ? filterTreeByIds(item.children, ids)
        : [];
      return item.children.length;
    }
    return match;
  });
};

/**
 * 通过ID集合删除数据
 * @param {Array<TreeItem>} tree 树结构数组对象
 * @param {Array} ids ids
 * @returns tree
 */
export const deleteByIdsFromTree = (tree, ids) => {
  const cloneTree = cloneDeep(tree);
  const newTree = cloneTree.filter((it) => !ids.includes(it._id));
  newTree.forEach((item) => {
    if (item.children && item.children.length) {
      item.children = deleteByIdsFromTree(item.children, ids);
    }
  });
  return newTree;
};

/**
 * 从树结构数组对象删除某一项
 * @param {Array<TreeItem>} tree 树结构数组对象
 * @param {String} key 对象key
 * @param {Any} value 对象值
 * @returns {Array<TreeItem>} 删除后的树
 */
export const deleteFromTree = (tree, key, value) => {
  const cloneTree = cloneDeep(tree);
  const newTree = cloneTree.filter((it) => it[key] !== value);
  newTree.forEach((item) => {
    if (item.children && item.children.length) {
      item.children = deleteFromTree(item.children, key, value);
    }
  });
  return newTree;
};

/**
 * 获取节点下所有的ID集合
 * @param {Array<TreeItem>} tree 树结构数组对象
 * @returns {Array} ID集合
 */
export const getChildIds = (tree) => {
  const ids = [];
  function loop(data, result) {
    data.forEach((item) => {
      result.push(item._id);
      if (item.children?.length) {
        loop(item.children, result);
      }
    });
  }
  loop(tree, ids);
  return ids;
};

/**
 * 数组2内的id是否在数组1中
 * @param {*} array1 数组1
 * @param {*} array2 数组2
 * @returns
 */
export function includesIds(array1, array2) {
  let flag = true;
  for (let i = 0; i < array2.length; i++) {
    const element = array2[i];
    if (!array1.includes(element)) {
      flag = false;
    }
  }
  return flag;
}

export function getSelectedKeys(tree, initKeys, type, key) {
  let selectedKeys = [...initKeys];
  const row = findForTree(tree, '_id', key);
  if (row) {
    if (!row._pid) {
      // 添加父级选中子级关联
      const childKeys = row.children?.map((it) => it._id);
      if (type === 'check') {
        // 选中
        selectedKeys = [...new Set(initKeys.concat(childKeys))];
      } else {
        // 取消选中
        selectedKeys = initKeys.filter(
          (it) => !childKeys.some((k) => it === k),
        );
      }
    } else {
      // 子级选中，关联选中父级
      const parentNode = findForTree(tree, '_id', row._pid);
      const childKeys = parentNode.children?.map((it) => it._id);
      if (type === 'check') {
        if (includesIds(selectedKeys, childKeys)) {
          // 子集id全部被选中，则将父级id也添加为选中
          selectedKeys = [...new Set(selectedKeys.concat([parentNode._id]))];
        }
      } else if (!includesIds(selectedKeys, childKeys)) {
        // 子集id并未被全部选中，将父级id取消选中
        selectedKeys = selectedKeys.filter((k) => parentNode._id !== k);
      }
    }
  }
  return selectedKeys;
}

/*
 * tree结构数据扁平化
 * @param {array} tree
 */
export function flattenList(tree) {
  const res = [];
  function fn(source) {
    source.forEach((el) => {
      res.push({
        ...el,
        children: [],
      });
      el.children && el.children.length > 0 && fn(el.children);
    });
  }
  fn(tree);
  return res;
}

export function getItemsFromTree(fromData = [], returnData = []) {
  fromData.forEach((item) => {
    if (item.tackSelect) {
      returnData.push(item);
    }
    if (item.children && item.children.length) {
      getItemsFromTree(item.children, returnData);
    }
  });
}

export function checkHasGrandson(list) {
  return list.some((son) => son.children && son.children.length);
}

export const checkHasValue = (tree, value) => {
  const data = flattenList(tree);
  return data.some((it) => it.href === value);
};

// 获取当前页面对应菜单下关联的key
export const getCurrentActiveByKey = (list, currentPath) => {
  for (const item of list) {
    if (checkHasValue(item.children || [], currentPath)) return item.key;
    if (item.href === currentPath) return item.key;
  }
};
