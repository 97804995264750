import $i18n from '@alife/panda-i18n';
import localeMap from '../../locale';
import { getEnv } from '../env';
const currentLang = $i18n.getLang();
// 多语言列表
export const langZh = 'zh-CN';
export const langEn = 'en-US';
export const langFr = 'fr-FR';
export const langZhHK = 'zh-HK';
export const locale = localeMap[currentLang];
export const classnames = (obj) => {
    let str = '';
    for (const [key, value] of Object.entries(obj)) {
        if (value) {
            str += `${key} `;
        }
    }
    return str;
};
export const getStartMenuCode = () => {
    const liveEnv = getEnv(); // daily|dev｜de-daily｜kul-daily, pre, prod, de-｜kul-
    if (['dev', 'daily', 'pre', 'prod'].includes(liveEnv)) {
        return 'HKG_MENU';
    }
    else if (['de-daily', 'de-pre', 'de-prod'].includes(liveEnv)) {
        return 'LGG_MENU';
    }
    else if (['kul-daily', 'kul-pre', 'kul-prod'].includes(liveEnv)) {
        return 'KUL_MENU';
    }
    return 'PC_MENU';
};
