import { memo, useMemo, useState } from 'react';
import { Box, CnIcon, Overlay } from '@alife/cn-ui';
import { classnames } from '../utils/shared';
import { setMemberCompany } from '../api';
const Component = ({ dataSource, companyId }) => {
    const [currentValue, setCurrentValue] = useState(companyId || '');
    const [visible, setVisible] = useState(false);
    const currentLabel = useMemo(() => {
        const map = (dataSource ?? []).reduce((tot, item) => {
            const totCop = { ...tot };
            totCop[item.value] = item.label;
            return totCop;
        }, {});
        return map[currentValue] || currentValue;
    }, [currentValue, dataSource]);
    return (<Overlay.Popup placement='b' v2 trigger={<Box direction='row' align='center' className='no-select' style={{
                display: currentLabel ? 'flex' : 'none',
                color: '#B4B4B4',
                fontSize: '16px',
                cursor: 'pointer',
                height: '56px',
            }}>
          <span style={{ marginRight: '5px' }}>{currentLabel}</span>
          <CnIcon type='arrow-right' size='medium'/>
        </Box>} container={(trigger) => trigger.parentNode} triggerType='click' visible={visible} onVisibleChange={(vi) => {
            if ((dataSource?.length ?? 0) > 1) {
                setVisible(vi);
            }
        }}>
      {dataSource?.length ? (<Box className='sub-panel-wrap'>
          <div>
            {(dataSource || []).map((item) => (<div className={classnames({
                    'sub-panel-item': true,
                    'sub-panel-item-active': currentValue === item.value,
                })} key={item.value}>
                <span onClick={async () => {
                    if (item.value !== currentValue) {
                        const res = await setMemberCompany({
                            companyId: item.value,
                        });
                        if (res) {
                            setCurrentValue(item.value);
                            window.location.reload();
                        }
                    }
                }}>
                  {item.label}
                </span>
              </div>))}
          </div>
        </Box>) : (<div />)}
    </Overlay.Popup>);
};
export default memo(Component);
