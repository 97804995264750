import React, { isValidElement } from 'react';
import { Box } from '@alife/cn-ui';
function Logo({ logo, logoHref }) {
    if (typeof logo === 'string' &&
        (logo?.startsWith('http') || logo?.startsWith('//'))) {
        return (
        // eslint-disable-next-line no-script-url
        <a href={logoHref || 'javascript:void(0)'}>
        <Box className='header-logo no-select' justify='center' align='center'>
          <img src={logo} alt='LOGO'/>
        </Box>
      </a>);
    }
    if (isValidElement(logo)) {
        return logo;
    }
    return null;
}
export default Logo;
