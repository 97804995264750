import { memo } from 'react';
import $i18n from '@alife/panda-i18n';
const Component = ({ dataSource, activeKey, handleClick, }) => {
    const switchLink = (item) => {
        if (item.href) {
            return (<a href={item.href} onClick={() => {
                    handleClick(item);
                }}>
          {$i18n.get({
                    id: `WORKBENCH.MENU.${item.menuCode}`,
                    dm: item.menuTitle || '',
                })}
        </a>);
        }
        return (<span onClick={() => {
                handleClick(item);
            }}>
        {$i18n.get({
                id: `WORKBENCH.MENU.${item.menuCode}`,
                dm: item.menuTitle || '',
            })}
      </span>);
    };
    return (<div>
      {(dataSource || []).map((item) => (<div className={`sub-panel-item ${activeKey === item.key ? 'sub-panel-item-active' : ''}`} key={item.key}>
          {switchLink(item)}
        </div>))}
    </div>);
};
export default memo(Component);
