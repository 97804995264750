import React, { useEffect, useMemo, useState } from 'react';
import { Row } from '@alife/cn-ui';
import MenuItem from './menu-item';
import { checkHasValue, getCurrentActiveByKey } from '../utils/tree';
import $i18n from '@alife/panda-i18n';
export default function CustomerMenu({ dataSource = [], onSelect, onTackSelect, }) {
    const { location } = window;
    const currentPath = useMemo(() => {
        return location.href.replace(location.origin, '').split('?')[0];
    }, [location.href, location.origin]);
    const [hover, setHover] = useState('');
    const checkIsSelect = (item) => {
        return (checkHasValue(item.children || [], currentPath) ||
            item.href === currentPath);
    };
    useEffect(() => {
        const target = getCurrentActiveByKey(dataSource, currentPath);
        if (target) {
            setHover(target);
        }
    }, [dataSource, currentPath]);
    return (<>
      {dataSource.length > 0 && (<Row>
          <div className='nav-custom-menu'>
            {dataSource.map((item, index) => (<React.Fragment key={item.key || index}>
                <div onMouseOver={() => {
                    setHover(item.key);
                }}>
                  <MenuItem text={$i18n.get({
                    id: `WORKBENCH.MENU.${item.menuCode}`,
                    dm: item.menuTitle || '',
                })} icon={item.icon} iconSize={item.iconSize} hasArrow={item.children && item.children.length > 0} isSelect={hover === item.key || checkIsSelect(item)} onSelect={() => {
                    !(item.children && item.children.length > 0) &&
                        onSelect(item);
                }} onTackSelect={() => {
                    onTackSelect(item, !item.tackSelect);
                }} tackSelect={item.tackSelect}/>
                </div>
              </React.Fragment>))}
          </div>
          <Row className='nav-custom-menu-item-children'>
            {dataSource.map((item, index) => {
                return hover === item.key &&
                    item.children &&
                    item.children.length > 0 ? (<CustomerMenu key={item.key || index} onSelect={(it) => {
                        onSelect(it);
                    }} dataSource={item.children} onTackSelect={(it) => {
                        onTackSelect(it, !it.tackSelect);
                    }}/>) : null;
            })}
          </Row>
        </Row>)}
    </>);
}
