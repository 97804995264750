/* eslint-disable no-param-reassign */
import React from 'react';
import './style/index.scss';
import Shell from './components/shell';
function View(props) {
    // // 异步请求写法示例，如果不涉及异步请求，请删掉这段代码
    // const requestService: IRequestService<ServiceResult, ServiceQuery> =
    //   handleRequestService(remoteUrl, service);
    // const { data = '' } = useRequest<ServiceResult, ServiceQuery>(
    //   requestService,
    //   {
    //     ready: !!(remoteUrl || service),
    //   },
    // );
    // // 异步请求代码结束位置
    return (<div className='main-header'>
      <Shell {...props}/>
    </div>);
}
export default View;
