/**
 * 基础信息路由
 */
export const BASIC_URL_LIST = [
    '/cargo-terminal-manage',
    '/warehouse-manage',
    '/country-info',
    '/city-info',
    '/airport-info',
    '/carrier-info',
    '/message/parsing-config',
    '/message/identify-config',
    '/data-report',
    '/ebilling-exp-report',
    '/ebilling-rcl-report',
    // --------
    '/ebilling',
    '/ebilling-m',
    '/plantform-transition',
    '/plantform-transition-list',
    '/return-cargo-list',
    '/cargo-plan',
    '/smart-build-plate',
    '/iot/rfid/detailed',
    '/iot/rfid/cargo-device-query',
    '/waybill-query',
    '/set-packages-query',
];
