export const checkIsKul = () => {
    const { hostname } = window.location;
    return hostname.includes('kul') || hostname.includes('sg.');
};
export const checkIsLgg = () => {
    const { hostname } = window.location;
    return hostname.includes('lgg');
};
// 获取环境
export function getEnv() {
    let env = 'prod';
    const { hostname, port } = window.location;
    const isDe = checkIsLgg();
    const isKul = checkIsKul();
    const localPort = port && `${port}` !== '80';
    const localhost = /(localhost|127.0.0.1)/.test(hostname);
    if (localPort || localhost) {
        env = 'dev';
    }
    else if (/(daily.|.test)/.test(hostname)) {
        if (isDe) {
            env = 'de-daily';
        }
        else if (isKul) {
            env = 'kul-daily';
        }
        else {
            env = 'daily';
        }
    }
    else if (/(pre.|pre-|.pre|-pre)/.test(hostname)) {
        if (isDe) {
            env = 'de-pre';
        }
        else if (isKul) {
            env = 'kul-pre';
        }
        else {
            env = 'pre';
        }
    }
    else if (isDe) {
        env = 'de-prod';
    }
    else if (isKul) {
        env = 'kul-prod';
    }
    return env;
}
const CNLOGIN_URL_MAP = {
    daily: 'cnlogin.cainiao.test',
    prod: 'cnlogin.cainiao.com',
    de: 'cnlogin.de.cainiao.com',
    sg: 'cnlogin.sg.cainiao.com',
};
/**
 * 获取当前环境的菜鸟会员登录域名
 * @returns String
 */
const getCnLoginUrl = () => {
    let cnLoginUrl = '';
    const liveEnv = getEnv();
    if (['dev', 'daily', 'de-daily', 'kul-daily'].includes(liveEnv)) {
        cnLoginUrl = CNLOGIN_URL_MAP.daily;
    }
    else if (liveEnv === 'de-pre' || liveEnv === 'de-prod') {
        cnLoginUrl = CNLOGIN_URL_MAP.de;
    }
    else if (liveEnv === 'kul-pre' || liveEnv === 'kul-prod') {
        cnLoginUrl = CNLOGIN_URL_MAP.sg;
    }
    else {
        cnLoginUrl = CNLOGIN_URL_MAP.prod;
    }
    return cnLoginUrl;
};
export const CN_LOGIN_URL = getCnLoginUrl();
