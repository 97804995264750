import React, { useState } from 'react';
import { Overlay, Box } from '@alife/cn-ui';
import CustomerMenu from './customer-menu';
const { Popup } = Overlay;
function GlobalMenu({ globalMenuTitle, dataSource, globalMenuTackSelect, }) {
    const [visible, setVisible] = useState(false);
    return (<Popup trigger={<div>
          <Box className='nav-menu-icon no-select' align='center' justify='center' style={{
                background: visible ? 'rgba(255,255,255, 0.2)' : 'transparent',
            }}>
            <img src='https://img.alicdn.com/imgextra/i3/O1CN01FDBsV01izGDjzeXLP_!!6000000004483-55-tps-26-22.svg' alt='菜单'/>
          </Box>
        </div>} container={(trigger) => trigger} visible={visible} triggerType='click' onVisibleChange={(v) => setVisible(v)} align='tl bl'>
      <div>
        <div className='nav-custom-menu-wrap'>
          <div className='nav-custom-menu-title'>
            <h3>{globalMenuTitle}</h3>
          </div>
          <div className='nav-custom-menu-listwrap'>
            <CustomerMenu dataSource={dataSource} onSelect={(item) => {
            if (item.href) {
                if (window.location.href === item.href) {
                    window.location.reload();
                }
                window.location.href = item.href;
                setVisible(false);
            }
        }} onTackSelect={(it, selected) => {
            globalMenuTackSelect(it, selected);
        }}/>
          </div>
        </div>
      </div>
    </Popup>);
}
export default GlobalMenu;
