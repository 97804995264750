import zhCN from './zh-CN.json';
import enUS from './en-US.json';
import frFr from './fr-FR.json';
import zhHK from './zh-HK.json';
const locale = {
    'zh-CN': zhCN,
    'en-US': enUS,
    'fr-FR': frFr,
    'zh-HK': zhHK,
};
export default locale;
