import { request } from '@alife/cn-request';

// 查询菜单
export async function queryMenu() {
  const { data } = await request({
    url: '/queryMenu',
    method: 'post',
  });
  return data;
}

// 查询菜单-新用户平台
export async function describeMemberMenu(startMenuCode = 'PC_MENU') {
  const { data } = await request({
    url: '/menu/describeMemberMenu',
    method: 'post',
    data: {
      startMenuCode,
      includeStartMenu: false,
    },
  });
  return data;
}

// 收藏菜单
export async function pinMenu(params) {
  const { data } = await request({
    url: '/pinMenu',
    method: 'post',
    data: params,
  });
  return data;
}

// 收藏菜单-新用户平台
export async function pinMenuNew(params) {
  const { data } = await request({
    url: '/menu/pinMenu',
    method: 'post',
    data: params,
  });
  return data;
}

// 取消收藏
export async function unpinMenu(params) {
  const { data } = await request({
    url: '/unpinMenu',
    method: 'get',
    params,
  });
  return data;
}
// 取消收藏-新用户平台
export async function unpinMenuNew(params) {
  const { data } = await request({
    url: '/menu/unpinMenu',
    method: 'get',
    params,
  });
  return data;
}

// 获取用户信息
export async function getUserInfo() {
  const { data } = await request({
    url: '/queryCurrentUserInfo',
    method: 'get',
  });
  return data;
}

// 获取用户信息-新用户平台
export async function describeCurrentMemberDetail() {
  const { data } = await request({
    url: '/user/describeCurrentMemberDetail',
    method: 'get',
  });
  return data;
}

// 设置用户企业-新用户平台
export async function setMemberCompany(params) {
  const { data } = await request({
    url: '/user/setMemberCompany',
    method: 'post',
    data: params,
  });
  return data;
}
