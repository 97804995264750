/* eslint-disable no-param-reassign */
import { Fragment, memo, useMemo, useState } from 'react';
import { Box, Overlay } from '@alife/cn-ui';
import { classnames, locale } from '../utils/shared';
import { checkHasGrandson, checkHasValue } from '../utils/tree';
import SubPanelSmall from './sub-panel-small';
import $i18n from '@alife/panda-i18n';
const { Popup } = Overlay;
const Component = ({ dataSource }) => {
    const { location } = window;
    const currentPath = useMemo(() => {
        return location.href.replace(location.origin, '').split('?')[0];
    }, [location.href, location.origin]);
    const [, setRefresh] = useState({});
    const _data = useMemo(() => {
        if (dataSource.length > 6) {
            const more = dataSource.slice(5);
            const base = dataSource.slice(0, 5);
            base.push({
                label: locale?.menuMore,
                menuTitle: locale?.menuMore,
                menuCode: 'menu_bar_more',
                _id: 'menu_bar_more',
                _pid: 0,
                key: 'menu_bar_more',
                children: more,
            });
            return base;
        }
        return dataSource;
    }, [dataSource]);
    const switchLink = (item, parent) => {
        if (item.href) {
            return (<a href={item.href} onClick={() => {
                    if (currentPath === item.href) {
                        window.location.reload();
                    }
                    if (parent) {
                        parent.itemVisible = false;
                        setRefresh({});
                    }
                }}>
          {$i18n.get({
                    id: `WORKBENCH.MENU.${item.menuCode}`,
                    dm: item.menuTitle || '',
                })}
        </a>);
        }
        return $i18n.get({
            id: `WORKBENCH.MENU.${item.menuCode}`,
            dm: item.menuTitle || '',
        });
    };
    // 二级菜单渲染
    const renderSubPanel = (parent) => {
        const data = parent.children || [];
        return (<div className={classnames({
                'sub-panel-wrap': true,
                'sub-panel-wrap-noAuto': data.length >= 6,
            })} style={{
                maxWidth: '100%',
                width: data.length >= 6 ? '100%' : 'auto',
            }}>
        <div className='sub-panel-wrap-column' style={{
                width: '100%',
                columns: data.length >= 6 ? 6 : data.length,
            }}>
          {data.map((item, index) => (<div className='sub-panel-wrap-column-item' style={{
                    breakInside: item.children && item.children?.length > 7 ? 'auto' : 'avoid',
                }} key={item.key || index}>
              <Box className={classnames({
                    'sub-menu-item-text': true,
                    'sub-menu-item-title': item.children?.length,
                    'sub-menu-item-active': currentPath === item.href,
                })} direction='row' align='center' style={{
                    color: item.children?.length ? '#4283ff' : '',
                }}>
                {switchLink(item, parent)}
                {item.children?.length ? (<div className='item-icon-right'/>) : null}
              </Box>
              {item?.children?.length && (<SubPanelSmall dataSource={item.children} activeKey={item.children.find((it) => it.href === currentPath)?.key} handleClick={(it) => {
                        if (currentPath === it.href) {
                            window.location.reload();
                        }
                        parent.itemVisible = false;
                        setRefresh({});
                    }}/>)}
            </div>))}
        </div>
      </div>);
    };
    // 菜单渲染
    const renderMultipleSubMenu = (item) => checkHasGrandson(item.children) ? (<Popup trigger={<Box className={classnames({
                'custom-menu-item': true,
                'custom-menu-item-active': checkHasValue(item.children || [], currentPath),
                'no-select': true,
            })} align='center' justify='center'>
            <span>
              {$i18n.get({
                id: `WORKBENCH.MENU.${item.menuCode}`,
                dm: item.menuTitle || '',
            })}
            </span>
          </Box>} container={(trigger) => trigger.parentNode.parentNode} visible={item.itemVisible} onVisibleChange={(vi) => {
            item.itemVisible = vi;
            setRefresh({});
        }} triggerType='hover' v2 placement='b'>
        {/* 最大宽100% */}
        {renderSubPanel(item)}
      </Popup>) : (<Popup trigger={<Box className={classnames({
                'custom-menu-item': true,
                'custom-menu-item-active': checkHasValue(item.children || [], currentPath),
                'no-select': true,
            })} align='center' justify='center'>
            <span>
              {$i18n.get({
                id: `WORKBENCH.MENU.${item.menuCode}`,
                dm: item.menuTitle || '',
            })}
            </span>
          </Box>} container={(trigger) => trigger.parentNode.parentNode} visible={item.itemVisible} onVisibleChange={(vi) => {
            item.itemVisible = vi;
            setRefresh({});
        }} triggerType='hover' v2 placement='b'>
        {/* 小菜单栏 */}
        <Box className='sub-panel-wrap'>
          <SubPanelSmall dataSource={item.children} activeKey={(item.children || []).find((it) => it.href === currentPath)?.key} handleClick={(it) => {
            if (currentPath === it.href) {
                window.location.reload();
            }
            item.itemVisible = false;
            setRefresh({});
        }}/>
        </Box>
      </Popup>);
    return (<Box direction='row' className='main-header-custom-wrap'>
      <Box direction='row' className='main-header-custom'>
        {_data.map((item) => (<Fragment key={item.key}>
            {item.children && item.children.length > 0 ? (renderMultipleSubMenu(item)) : (<Box className={classnames({
                    'custom-menu-item': true,
                    'custom-menu-item-active': item.href === currentPath,
                    'no-select': true,
                })} align='center' justify='center'>
                {/* 导航上 */}
                {switchLink(item)}
              </Box>)}
          </Fragment>))}
      </Box>
    </Box>);
};
export default memo(Component);
